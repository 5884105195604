import { FacebookPage } from "./facebookPage";
import { FacebookPostComment } from "./facebookPostComment";
import { FacebookUser } from "./facebookUser";

export class FacebookPost {
    constructor() {
        this.published = false;
        this.postType = 1;
        this.facebookPostComments = [];
    }

    facebookPostId?: string;
    facebookUserId?: string;
    facebookPageId?: string;
    postType: number;
    message?: string;
    link?: string;
    published: boolean;
    id?: string;
    commentsCount?: number;
    imageUrl?: string;

    facebookUser?: FacebookUser;
    facebookPage?: FacebookPage;

    facebookPostComments?: FacebookPostComment[];
}