import { FacebookPost } from "../facebook";
import { HttpRequest } from "../httpRequests";

export class TaskLine {
    constructor() {
        this.processed = false;
    }

    taskLineId?: string;
    taskId?: string;
    processed?: boolean;
    sourceId?: string;
    addedDate?: Date;
    runDate?: Date;
    index?: number;
    nextRunDate?: Date;

    httpRequest?: HttpRequest;
    facebookPost?: FacebookPost;
}