import { AiModel, AiTemplate } from "../ai";

export class FacebookAiPostsWizard {
    constructor() {
        this.aiTemplate = new AiTemplate();
        this.aiModel = new AiModel();
    }

    aiTemplate?: AiTemplate;
    aiModel?: AiModel;
    isListResult?: boolean;
}