import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, PageQuery, PinterestBoard } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinterestBoardsService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<PinterestBoard>> {
    return this.http.post<Page<PinterestBoard>>(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoardsPaged}`,
      query
    );
  }

  getById(id: string) {
    return this.http.get<PinterestBoard>(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoards}/${id}`
    );
  }

  sync() {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoardsSync}`,
      undefined
    );
  }

  add(entity: PinterestBoard) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoards}`,
      entity
    );
  }

  edit(entity: PinterestBoard) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoards}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.pinterestBoards}/${id}`
    );
  }
}
