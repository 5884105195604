import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSessionPersistenceService } from './user-session-persistence.service';
import { environment } from '@environments/environment';
import { endpoints } from '@environments/endpoints';
import { Credentials, User, UserSession } from '@core/models';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private userSessionPersistenceService: UserSessionPersistenceService) { }

  login(credentials: Credentials) {
    return this.http
      .post<any>(
        `${environment.authApiUrl}/${endpoints.auth.v1.authLogin}`,
        credentials
      )
      .pipe(
        map((user: User) => {
          if (user && user.userSession) {
            this.userSessionPersistenceService.setUserSession(user.userSession);
          }

          return user;
        })
      );
  }

  refreshToken(userSession: UserSession) {
    return this.http.post<UserSession>(
      `${environment.authApiUrl}/${endpoints.auth.v1.authRefreshToken}`,
      userSession
    );
  }

  logout(userSession: UserSession) {
    return this.http.post<UserSession>(
      `${environment.authApiUrl}/${endpoints.auth.v1.authLogout}`,
      userSession
    );
  }
}
