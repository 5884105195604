import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacebookPage, Page, PageQuery } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacebookPagesService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<FacebookPage>> {
    return this.http.post<Page<FacebookPage>>(
      `${environment.apiUrl}/${endpoints.v1.facebookPagesPaged}`,
      query
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.facebookPages}/${id}`
    );
  }
}
