import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, PageQuery, TaskType } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskTypesService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<TaskType>> {
    return this.http.post<Page<TaskType>>(
      `${environment.apiUrl}/${endpoints.v1.taskTypesPaged}`,
      query
    );
  }

  getAll() {
    return this.http.get<TaskType[]>(`${environment.apiUrl}/${endpoints.v1.taskTypes}`);
  }

  getById(id: string) {
    return this.http.get<TaskType>(
      `${environment.apiUrl}/${endpoints.v1.taskTypes}/${id}`
    );
  }

  add(entity: TaskType) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.taskTypes}`,
      entity
    );
  }

  update(entity: TaskType) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.taskTypes}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.taskTypes}/${id}`
    );
  }
}
