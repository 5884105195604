import { PageQuery } from "../page";

export class FacebookPostPageQuery extends PageQuery {
    public static override empty(): FacebookPostPageQuery {
        var result = new FacebookPostPageQuery();

        result.pageNumber = 1;
        result.pageSize = 10;

        return result;
    }

    showOnlyNotScheduled?: boolean;
    showOnlyNotPublished?: boolean;
}