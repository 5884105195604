import { TaskLine } from "./taskLine";
import { TaskRepeatType } from "./taskRepeatType";
import { TaskType } from "./taskType";

export class Task {
    constructor() {
        this.active = true;
        this.taskLines = [];
        this.taskRepeatTypeId = 0;
        this.repeatEveryNumber = 1;
        this.repeatEveryUnit = 1;
        this.endsNever = true;
        this.runDate = new Date();
    }

    taskId?: string;
    name?: string;
    active?: boolean;
    taskTypeId?: number;
    addedDate?: Date;
    lastRunDate?: Date;
    runDate?: Date;
    taskRepeatTypeId?: number;
    repeatEveryNumber?: number;
    repeatEveryUnit?: number;
    repeatDaysOfWeek?: string;
    endsNever?: boolean;
    endsDate?: Date;
    endsAfterNumber?: number;
    occurrences?: number;

    taskRepeatType?: TaskRepeatType;
    taskType?: TaskType;
    taskLines?: TaskLine[];
}