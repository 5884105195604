import { FacebookPage, FacebookPost } from "../facebook";
import { Task } from "../tasks";

export class FacebookPostsWizard {
    constructor() {
        this.task = new Task();
        this.facebookPage = new FacebookPage();
        this.facebookPost = new FacebookPost();
        this.messages = [];
    }

    task?: Task;
    facebookPage?: FacebookPage;
    facebookPost?: FacebookPost;
    createCommentWithPostLink?: boolean;
    postCommentPrefix?: string;
    messages?: string[];
}