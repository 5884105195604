export class File {
    fileId?: string;
    userId?: string;
    name?: string;
    contentType?: string;
    createdOn?: Date;
    lastModified?: Date;
    lastAccessedOn?: Date;
    size?: number;
    url?: string;
    publicFile?: boolean;
}