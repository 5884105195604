import { PageQuery } from "../page";

export class HttpRequestPageQuery extends PageQuery {
    public static override empty(): HttpRequestPageQuery {
        var result = new HttpRequestPageQuery();

        result.pageNumber = 1;
        result.pageSize = 10;

        return result;
    }

    showOnlyNotScheduled?: boolean;
}