import { PinterestAccount } from "./pinterest-account";
import { PinterestBoardSection } from "./pinterest-board-section";

export class PinterestBoard {
    constructor() {
        this.pinterestBoardSections = [];
    }

    pinterestBoardId?: string;
    userId?: string;
    pinterestAccountId?: string;
    id?: string | null;
    name?: string;
    description?: string;
    privacy?: string;
    createdAt?: string;
    imageCoverUrl?: string;
    pinCount?: number;
    boardPinsModifiedAt?: string;
    followerCount?: number;

    pinterestAccount?: PinterestAccount;
    pinterestBoardSections?: PinterestBoardSection[];
}