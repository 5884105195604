import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacebookAiPostsWizard, FacebookPostsWizard } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookWizardsService {
  constructor(private http: HttpClient) { }

  aiPosts(entity: FacebookAiPostsWizard) {
    return this.http.post<string[]>(
      `${environment.apiUrl}/${endpoints.v1.facebookWizardsAiPosts}`,
      entity
    );
  }

  posts(entity: FacebookPostsWizard) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.facebookWizardsPosts}`,
      entity
    );
  }
}
