export const endpoints = {
    auth: {
        v1: {
            authLogin: 'v1/auth/login',
            authRefreshToken: 'v1/auth/refreshToken',
            authLogout: 'v1/auth/logout'
        }
    },
    v1: {
        "tasks": "v1/tasks",
        "tasksPaged": "v1/tasks/paged",
        "taskTypes": "v1/taskTypes",
        "taskTypesPaged": "v1/taskTypes/paged",
        "httpRequests": "v1/httpRequests",
        "httpRequestsPaged": "v1/httpRequests/paged",
        "facebookPermissions": "v1/facebookPermissions",
        "facebookPermissionsCommaSeparated": "v1/facebookPermissions/commaSeparated",
        "facebookPermissionsPaged": "v1/facebookPermissions/paged",
        "facebookUsers": "v1/facebookUsers",
        "facebookUsersPaged": "v1/facebookUsers/paged",
        "facebookPages": "v1/facebookPages",
        "facebookPagesPaged": "v1/facebookPages/paged",
        "facebookPosts": "v1/facebookPosts",
        "facebookPostsPaged": "v1/facebookPosts/paged",
        "pinterestScopes": "v1/pinterestScopes",
        "pinterestScopesPaged": "v1/pinterestScopes/paged",
        "pinterestScopesCommaSeparated": "v1/pinterestScopes/commaSeparated",
        "pinterestLoginUrl": "v1/pinterestAuth/loginUrl",
        "pinterestAccounts": "v1/pinterestAccounts",
        "pinterestAccountsPaged": "v1/pinterestAccounts/paged",
        "pinterestBoards": "v1/pinterestBoards",
        "pinterestBoardsPaged": "v1/pinterestBoards/paged",
        "pinterestBoardsSync": "v1/pinterestBoards/sync",
        "aiModels": "v1/aiModels",
        "aiModelsPaged": "v1/aiModels/paged",
        "aiModelsNextIndex": "v1/aiModels/nextIndex",
        "aiTemplates": "v1/aiTemplates",
        "aiTemplatesPaged": "v1/aiTemplates/paged",
        "facebookWizardsPosts": "v1/facebookWizards/posts",
        "facebookWizardsAiPosts": "v1/facebookWizards/aiPosts",
        "files": "v1/files",
        "filesSyncCloud": "v1/files/syncCloud",
        "filesPaged": "v1/files/paged",
        "filesUpload": "v1/files/upload",
        "filesDownload": "v1/files/download"
    }
}