import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class RandomStringService {
  public static generateRandomEightString(): string {
    return uuidv4().toString().substring(0, 7);
  }

  public static guid(): string {
    return uuidv4();
  }
}
