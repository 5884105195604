import { HttpRequestHeader } from "./httpRequestHeader";

export class HttpRequest {
    httpRequestId?: string;
    url?: string;
    requestMethod?: string;
    name?: string;
    addedDate?: Date;
    body?: string;
    
    requestHeaders?: HttpRequestHeader[];
}