import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { File, Page, PageQuery, UploadFile } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<File>> {
    return this.http.post<Page<File>>(
      `${environment.apiUrl}/${endpoints.v1.filesPaged}`,
      query
    );
  }

  getAll() {
    return this.http.get<File[]>(`${environment.apiUrl}/${endpoints.v1.files}`);
  }

  getById(id: string) {
    return this.http.get<File>(
      `${environment.apiUrl}/${endpoints.v1.files}/${id}`
    );
  }

  update(entity: File) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.files}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.files}/${id}`
    );
  }

  syncCloud() {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.filesSyncCloud}`,
      undefined
    );
  }

  upload(data: any, uploadFile: UploadFile) {
    const headers = new HttpHeaders().set('path', uploadFile.path!)
      .set('enforceReplace', uploadFile.enforceReplace!.toString())
      .set('publicFile', uploadFile.publicFile!.toString());

    return this.http.post<File>(
      `${environment.apiUrl}/${endpoints.v1.filesUpload}`,
      data,
      {
        reportProgress: true,
        observe: 'events',
        headers,
      }
    );
  }

  download(fileId: string) {
    return this.http.get(`${environment.apiUrl}/${endpoints.v1.filesDownload}/${fileId}`,
      {
        responseType: 'arraybuffer'
      }
    );
  }
}
