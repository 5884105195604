import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly themeSubject = new BehaviorSubject<string | undefined>(
    localStorage.getItem('theme') ?? undefined
  );

  constructor() { }

  public getThemeClass(): string | undefined {
    return localStorage.getItem('theme') ?? undefined;
  }

  public setThemeClass(theme: string) {
    localStorage.setItem('theme', theme);
    this.themeSubject.next(theme);
  }

  public removeTheme() {
    localStorage.removeItem('theme');
    this.themeSubject.next(undefined);
  }

  public getThemeChange(): Observable<string | undefined> {
    return this.themeSubject.asObservable().pipe(distinctUntilChanged());
  }
}
