import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, PageQuery, Task } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<Task>> {
    return this.http.post<Page<Task>>(
      `${environment.apiUrl}/${endpoints.v1.tasksPaged}`,
      query
    );
  }

  getById(id: string) {
    return this.http.get<Task>(
      `${environment.apiUrl}/${endpoints.v1.tasks}/${id}`
    );
  }

  add(entity: Task) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.tasks}`,
      entity
    );
  }

  update(entity: Task) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.tasks}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.tasks}/${id}`
    );
  }
}
