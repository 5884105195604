import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, PageQuery, PinterestAccount, PinterestAuthAccessToken } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinterestAccountsService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<PinterestAccount[]> {
    return this.http.get<PinterestAccount[]>(
      `${environment.apiUrl}/${endpoints.v1.pinterestAccounts}`
    );
  }

  getPaged(query: PageQuery): Observable<Page<PinterestAccount>> {
    return this.http.post<Page<PinterestAccount>>(
      `${environment.apiUrl}/${endpoints.v1.pinterestAccountsPaged}`,
      query
    );
  }

  getById(id: string): Observable<PinterestAccount> {
    return this.http.get<PinterestAccount>(
      `${environment.apiUrl}/${endpoints.v1.pinterestAccounts}/${id}`
    );
  }

  addEdit(pinterestAuthAccessToken: PinterestAuthAccessToken): Observable<PinterestAccount> {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.pinterestAccounts}`,
      pinterestAuthAccessToken
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/${endpoints.v1.pinterestAccounts}/${id}`);
  }
}
