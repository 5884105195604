export class PageQuery {
    public static empty(): PageQuery {
        var result = new PageQuery();

        result.pageNumber = 1;
        result.pageSize = 10;

        return result;
    }

    public static empty20Items(): PageQuery {
        var result = new PageQuery();

        result.pageNumber = 1;
        result.pageSize = 20;

        return result;
    }

    pageNumber?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}