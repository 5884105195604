import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PinterestLoginUrl } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinterestAuthService {
  constructor(private http: HttpClient) { }

  getPinterestLoginUrl(): Observable<PinterestLoginUrl> {
    return this.http.get(
      `${environment.apiUrl}/${endpoints.v1.pinterestLoginUrl}`
    );
  }
}