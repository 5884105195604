import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PinterestScope, Page, PageQuery } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinterestScopesService {
  constructor(private http: HttpClient) { }

  getPaged(query: PageQuery): Observable<Page<PinterestScope>> {
    return this.http.post<Page<PinterestScope>>(
      `${environment.apiUrl}/${endpoints.v1.pinterestScopesPaged}`,
      query
    );
  }

  getCommaSeparated() {
    return this.http.get(`${environment.apiUrl}/${endpoints.v1.pinterestScopesCommaSeparated}`, {
      responseType: 'text'
    });
  }

  getById(id: string) {
    return this.http.get<PinterestScope>(
      `${environment.apiUrl}/${endpoints.v1.pinterestScopes}/${id}`
    );
  }

  add(entity: PinterestScope) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.pinterestScopes}`,
      entity
    );
  }

  update(entity: PinterestScope) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.pinterestScopes}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.pinterestScopes}/${id}`
    );
  }
}
