import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AiModel, Page, PageQuery } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiModelsService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<AiModel[]>(
      `${environment.apiUrl}/${endpoints.v1.aiModels}`
    );
  }

  getPaged(query: PageQuery): Observable<Page<AiModel>> {
    return this.http.post<Page<AiModel>>(
      `${environment.apiUrl}/${endpoints.v1.aiModelsPaged}`,
      query
    );
  }

  getById(id: number) {
    return this.http.get<AiModel>(
      `${environment.apiUrl}/${endpoints.v1.aiModels}/${id}`
    );
  }

  getNextIndex() {
    return this.http.get(`${environment.apiUrl}/${endpoints.v1.aiModelsNextIndex}`, {
      responseType: 'text'
    });
  }

  add(entity: AiModel) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.aiModels}`,
      entity
    );
  }

  update(entity: AiModel) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.aiModels}`,
      entity
    );
  }

  delete(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.aiModels}/${id}`
    );
  }
}
