import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpRequestPageQuery, Page } from '@core/models';
import { endpoints } from '@environments/endpoints';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {
  constructor(private http: HttpClient) { }

  getPaged(query: HttpRequestPageQuery): Observable<Page<HttpRequest>> {
    return this.http.post<Page<HttpRequest>>(
      `${environment.apiUrl}/${endpoints.v1.httpRequestsPaged}`,
      query
    );
  }

  getById(id: string) {
    return this.http.get<HttpRequest>(
      `${environment.apiUrl}/${endpoints.v1.httpRequests}/${id}`
    );
  }

  add(entity: HttpRequest) {
    return this.http.post(
      `${environment.apiUrl}/${endpoints.v1.httpRequests}`,
      entity
    );
  }

  update(entity: HttpRequest) {
    return this.http.put(
      `${environment.apiUrl}/${endpoints.v1.httpRequests}`,
      entity
    );
  }

  delete(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/${endpoints.v1.httpRequests}/${id}`
    );
  }
}
