import { Injectable } from '@angular/core';
import { UserSession } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class UserSessionPersistenceService {
  userSession: UserSession | undefined;

  constructor() { }

  public getUserSession(): UserSession | undefined {
    var userLocalStorage = localStorage.getItem('user-session');
    if (userLocalStorage) {
      this.userSession = JSON.parse(userLocalStorage);
    }
    else {
      this.userSession = undefined;
    }

    return this.userSession;
  }

  public setUserSession(userSession: UserSession) {
    localStorage.setItem('user-session', JSON.stringify(userSession));

    this.userSession = userSession;
  }

  public removeUserSession() {
    localStorage.removeItem('user-session');
  }
}
